import React from "react"
import Layout from "../../components/layout"
import PcpHero from "../../components/PcpHero"
import Breadcrumbs from "../../components/Breadcrumbs"
import VideoModule from "../../components/VideoModule"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import SEO from "../../components/seo"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/pages/hubble-lenses.scss"
import "../../styles/pages/skyhy-pcp.scss"

import PcpList, { formatPcpItem } from "../../components/PcpList"
import {getHubblePricing, skyhyNewAdvantages} from "../../services/data";
import {contactLenses as getContactLenses} from "../../components/LensCompare/mockedData";
import useIsLifemart from "../../components/Lifemart/hooks/useIsLifemart";
import useIsMilitary from "../../components/Military/hooks/useIsMilitary";
import ProductSectionAdvantages from "../../components/ProductSectionAdvantages";

const SkyhyPcp = ()  => {
  const hubblePricing = getHubblePricing()
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary, hubblePricing)

  let skyhyList = ["skyhyMonthly", "skyhy"]
  let products = contactLenses.filter(e => skyhyList.includes(e.type))

  return (
    <Layout className="skyhy-pcp">
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door. Try Hubble’s classic, Hydro, and SkyHy contact lenses."
      />
      <PcpHero
        heroText={<>SkyHy <br/>by Hubble<sup>®</sup></>}
        heroImage="none"
        background="purple"
        bannerClass="purple promo-banner pcp"
        bannerText={{
          desktop: <>Get our NEW SkyHy by Hubble Monthly for <b>40% OFF</b> of your first order. <span className='cta-button-banner'>Shop Now</span></>,
          mobile: <>Get our NEW SkyHy by Hubble Monthly for <br/><b>40% OFF</b> of your first order. <span className='cta-button-banner'>Shop Now</span></>,
        }}
        bannerLink="/contact-lenses/skyhy-monthly-by-hubble-lenses"
      />
      <div className="pcp-container">
        <Breadcrumbs links={[{ to: "/", label: "Homepage" }, { label: "SkyHy by Hubble" }]} />
        <PcpList products={products} />
      </div>
      <div className="divider"></div>
      <ProductSectionAdvantages
        list={skyhyNewAdvantages}
        subheader="the most breathable"
        header={<><b>Sky</b>Hy by Hubble<sup>®</sup> Collection</>}
      />
      <HowHubbleWorksCarousel color="purple"/>
      <div className="video-container">
        <VideoModule
          type={"contacts"}
          video={"https://www.youtube.com/embed/quoNFn7dxTw"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <div className="pt-5 mt-0 mt-sm-5"></div>
    </Layout>
  )
}

export default SkyhyPcp
